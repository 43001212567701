import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export const useStyles = makeStyles(theme => ({
  root: () => ({
    padding: '40px 0 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 0 40px'
    }
  }),
  accordion: {
    boxShadow: 'none',
    borderBottom: '1px solid #E0E0E0',
    '&:before': {
      display: 'none'
    },
    '&.Mui-expanded': {
      margin: '0'
    }
  },
  summary: {
    padding: '0',
    fontWeight: '700',
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    },
    '& .MuiAccordionSummary-content': {
      margin: '24px 0',
      [theme.breakpoints.down('xs')]: {
        margin: '16px 0 10px'
      }
    }
  },
  details: {
    paddingLeft: '0',
    paddingRight: '0',
    '& a': {
      color: theme.palette.brand.main,
      '&:hover': {
        color: theme.palette.brand.main
      }
    },
    '& .MuiAccordionDetails-root': {}
  }
}))

const FAQ = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <Container maxWidth="md">
        <Typography variant="h2" align="center" gutterBottom>
          FAQ
        </Typography>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.summary}
          >
            What is PGA Coach and who is it for?
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Box>
              <Typography variant="body1">
                PGA Coach is a two-sided platform that provides golfers with a
                marketplace to find and connect with a coach, and offers golf
                coaches a comprehensive suite of tools and industry-leading
                training to help streamline their business and maximize their
                potential.
              </Typography>
              <br />
              <Typography variant="body1">
                It also supports the unique needs of PGA of America Programs
                like PGA Jr. League, PGA Family Golf, PGA HOPE and PGA Coaching
                Center.
              </Typography>
              <Typography variant="body1">
                For more FAQs, visit the{' '}
                <a
                  href="https://support.pga.com/en/collections/2683103-pga-coach-website-mobile-app-coaches"
                  target="_blank"
                  rel="noreferrer"
                >
                  PGA Coach Help Center
                </a>
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.summary}
          >
            What are the benefits of PGA Coach if I already have a successful
            business or work at a private club?
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Typography variant="body1">
              PGA Coach takes an à la carte, flexible approach to support your
              coaching business wherever you need it most. We’ve provided a
              variety of ways to leverage the platform, whether you’re at a
              private club or if you can’t take on any new students. Here are a
              few examples:
              <br />
              <ul>
                <li>
                  <a
                    href="https://my.pga.com/pga-coach/profile"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Create a Coach Profile
                  </a>{' '}
                  to reserve your spot on pga.com, even if you don’t need it
                  right now. You can set it to be hidden and show it whenever
                  you want to promote a group event, an individual coaching
                  session or a PGA of America Program like PGA Jr. League, PGA
                  Family Golf or PGA HOPE.
                </li>
                <li>
                  <a
                    href="https://my.pga.com/pga-coach"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Leverage the business tools
                  </a>{' '}
                  with your existing client base to handle all your
                  behind-the-scenes booking, payments, student management and
                  messaging, while keeping all sessions protected and hidden to
                  the public,
                </li>
                <li>
                  <a
                    href="https://pgacoach.lightspeedvt.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Take advantage of six education courses
                  </a>{' '}
                  available to help you continue to evolve as a coach.
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.summary}
          >
            How do I get listed on PGA.com?
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Box>
              <Typography variant="body1">
                Get listed in three easy steps:
                <ol>
                  <li>
                    <a
                      href="https://my.pga.com/pga-coach"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Create a coach profile,
                    </a>{' '}
                    which will automatically generate your own coach webpage on
                    PGA.com. You will be able to share this link with your
                    students.{' '}
                  </li>
                  <li>
                    Add bookings or group events so golfers can view and book
                    sessions with you online.{' '}
                  </li>
                  <li>
                    To be featured on PGA.com’s homepage and leaderboards, be
                    sure to{' '}
                    <a
                      href="https://pgacoach.lightspeedvt.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      get ADM certified.
                    </a>{' '}
                  </li>
                </ol>
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.summary}
          >
            What are the fees associated with using PGA Coach?
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Box>
              <Typography variant="body1">
              PGA Coach is free to use, making it accessible for most coaches. For those looking to enhance their capabilities, we offer PGA Coach+, which is competitively priced at $25 per month or $250 annually. Subscriptions for PGA Coach+ can be easily managed through my.pga.com.
              </Typography>
              <br />
              <Typography variant="body1">
                For more FAQs, visit the{' '}
                <a
                  href="https://support.pga.com/en/collections/2683103-pga-coach-website-mobile-app-coaches"
                  target="_blank"
                  rel="noreferrer"
                >
                  PGA Coach Help Center
                </a>
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Box>
  )
}

export default FAQ
